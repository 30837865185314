
import { Options, Vue } from "vue-class-component";
import { LockClosedIcon } from "@heroicons/vue/solid";
import { RegisterType, RegisterTypeDefault, RegisterFormType } from "../types/user/register-type";
import { registerHandler } from "../handlers/user/access-handlers";
import { AlertSeverities, showAlert } from "../helpers/alerts";

@Options({
  components: {
    LockClosedIcon,
  },
})
export default class Register extends Vue {
  private credentials: RegisterFormType = RegisterTypeDefault;
  private button = "Registrovat!";

  async handleRegister(): Promise<void> {
    if (this.credentials.email !== this.credentials.email_confirmation) return showAlert("Něco tu nesedí", "Emaily se neshodují!", AlertSeverities.info);
    if (this.credentials.password !== this.credentials.password_confirmation) return showAlert("Něco tu nesedí", "Hesla se neshodují!", AlertSeverities.info);

    const payload: RegisterType = {
      username: this.credentials.username,
      password: this.credentials.password,
      email: this.credentials.email,
    };

    this.button = "Načítám!";

    const response = await registerHandler(payload);
    this.button = "Registrovat!";
    if (response === undefined) return;
    await this.$router.push({ name: "Login" });
  }
}
