export interface RegisterFormType {
  username: string;
  email: string;
  email_confirmation: string;
  password: string;
  password_confirmation: string;
}

export const RegisterTypeDefault: RegisterFormType = {
  username: "",
  email: "",
  email_confirmation: "",
  password: "",
  password_confirmation: "",
};

export interface RegisterType {
  username: string;
  email: string;
  password: string;
}
